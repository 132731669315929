import {
    Link,
    //useLocation 
} from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = ({ handleLogout }) => {
    // const location = useLocation(); // Get the current route

    return (
        <nav className="navbar">
            {/* 
                <div className="navbar-left">
                <Link to="/" className="navbar-logo">
                    <img src={ncLogo} alt="PT Logo" width="40" height="40" style={{ marginRight: '5px' }} />
                    PixelTycoons
                </Link>
            </div>

            */}

            <div className="navbar-center">
                {/*<Link className="link" to="/seed-round">Seed Round</Link>*/}
                <Link className="link" to="/stash">Stash</Link>
                <Link className="link" to="/tools">Tools</Link>
                <Link className="link" to="/tag">Tag Battle</Link>
                <Link className="link" to="/staking">Staking</Link>
            </div>

            <div className="navbar-right">

                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
        </nav>
    );
};


export default Navbar;