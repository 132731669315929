import React, { useEffect, useState } from "react";

const Countdown = ({ endTimestamp, text = "Time Remaining" }) => {
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = Math.floor(Date.now() / 1000); // Current time in seconds
            const timeRemaining = endTimestamp - now;

            if (timeRemaining <= 0) {
                setTimeLeft("Staking period has ended.");
                return;
            }

            const days = Math.floor(timeRemaining / 86400); // 86400 seconds in a day
            const hours = Math.floor((timeRemaining % 86400) / 3600);
            const minutes = Math.floor((timeRemaining % 3600) / 60);
            const seconds = timeRemaining % 60;

            setTimeLeft(
                `${days}d ${hours}h ${minutes}m ${seconds}s`
            );
        };

        // Update the countdown every second
        const timer = setInterval(calculateTimeLeft, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(timer);
    }, [endTimestamp]);

    return (
        <div>

            <p><strong>{text}</strong>: <strong style={{ fontSize: "1.5rem", color: "#0929f0" }}>{timeLeft}</strong></p>
        </div>
    );
};

export default Countdown;
