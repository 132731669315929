import React, { useState } from "react";
import Web3 from "web3";
import Navbar from "../widgets/NavBar";

import "../css/Stash.css"
// Contract details
const CONTRACT_ADDRESS = "0x5B73743d6e99E911e6C412C0BcA9a702475F0595";
const ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "locker",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "LockRemoved",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "locker",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "unlockTime",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "label",
                "type": "string"
            }
        ],
        "name": "TokensLocked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "locker",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "label",
                "type": "string"
            }
        ],
        "name": "TokensUnlocked",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_locker",
                "type": "address"
            }
        ],
        "name": "getLocks",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "unlockTime",
                        "type": "uint256"
                    },
                    {
                        "internalType": "string",
                        "name": "label",
                        "type": "string"
                    }
                ],
                "internalType": "struct Stash.LockedToken[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_duration",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_label",
                "type": "string"
            }
        ],
        "name": "lockTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "locks",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "unlockTime",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "label",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenCreator",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_index",
                "type": "uint256"
            }
        ],
        "name": "unlockTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

const LockTokens = () => {
    const [account, setAccount] = useState(null);
    const [web3, setWeb3] = useState(null);
    const [contract, setContract] = useState(null);
    const [amount, setAmount] = useState(""); // Amount in tokens
    const [duration, setDuration] = useState(""); // Duration in seconds
    const [label, setLabel] = useState(""); // Label for the lock
    const [status, setStatus] = useState(""); // Status message
    const [locks, setLocks] = useState([]); // User's locks

    // Connect to wallet
    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                const provider = window.ethereum;
                await provider.request({ method: "eth_requestAccounts" });
                const web3Instance = new Web3(provider);
                const accounts = await web3Instance.eth.getAccounts();
                const contractInstance = new web3Instance.eth.Contract(ABI, CONTRACT_ADDRESS);
                setWeb3(web3Instance);
                setAccount(accounts[0]);
                setContract(contractInstance);
                setStatus("Wallet connected successfully!");
                fetchLocks(contractInstance, accounts[0]);
            } catch (error) {
                setStatus(`Error connecting wallet: ${error.message}`);
            }
        } else {
            setStatus("MetaMask is not installed!");
        }
    };

    // Fetch all locks for the user
    const fetchLocks = async (contractInstance, userAddress) => {
        try {
            const userLocks = await contractInstance.methods.getLocks(userAddress).call();
            setLocks(userLocks);
        } catch (error) {
            console.error("Error fetching locks:", error);
        }
    };

    // Lock tokens
    const lockTokens = async () => {
        if (!web3 || !account || !contract) {
            setStatus("Please connect your wallet first.");
            return;
        }

        try {
            const weiAmount = web3.utils.toWei(amount, "ether"); // Convert amount to wei
            const gasEstimate = await contract.methods
                .lockTokens(weiAmount, duration, label)
                .estimateGas({ from: account });

            await contract.methods
                .lockTokens(weiAmount, duration, label)
                .send({ from: account, gas: gasEstimate });

            setStatus("Tokens locked successfully!");
            fetchLocks(contract, account); // Refresh locks after successful transaction
        } catch (error) {
            setStatus(`Transaction failed: ${error.message}`);
        }
    };

    // Unlock tokens
    const unlockTokens = async (index) => {
        if (!web3 || !account || !contract) {
            setStatus("Please connect your wallet first.");
            return;
        }

        try {
            const gasEstimate = await contract.methods
                .unlockTokens(index)
                .estimateGas({ from: account });

            await contract.methods.unlockTokens(index).send({ from: account, gas: gasEstimate });
            setStatus("Tokens unlocked successfully!");
            fetchLocks(contract, account); // Refresh locks after successful transaction
        } catch (error) {
            setStatus(`Transaction failed: ${error.message}`);
        }
    };

    return (
        <div className="stash">
            <Navbar />
            <h1 className="title">ShidoKid Stash</h1>
            {!account ? (
                <div>
                    <h3>Login to see content</h3>
                    <button className="login-btn" onClick={connectWallet}>
                        Connect Wallet
                    </button>
                </div>
            ) : (
                <div>
                    <h2>Lock Tokens</h2>
                    <p>Account: {account}</p>
                    <input
                        type="text"
                        placeholder="Amount (in tokens)"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Duration (in seconds)"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Label"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                    />
                    <button onClick={lockTokens}>Lock Tokens</button>

                    <h2>Your Locks</h2>
                    {locks.length === 0 ? (
                        <p>No locks found.</p>
                    ) : (
                        <div className="locks">
                            {locks.map((lock, index) => (
                                <div className="lock" key={index}>
                                    <p><strong>Amount:</strong> {web3.utils.fromWei(lock.amount, "ether")} tokens</p>
                                    <p><strong>Unlock Time:</strong> {new Date(lock.unlockTime * 1000).toLocaleString()}</p>
                                    <p><strong>Label:</strong> {lock.label}</p>
                                    {Date.now() / 1000 > lock.unlockTime ? (
                                        <button onClick={() => unlockTokens(index)}>Unlock Tokens</button>
                                    ) : (
                                        <p>Unlock available after: {new Date(lock.unlockTime * 1000).toLocaleString()}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    <p>{status}</p>
                </div>
            )}
        </div>
    );
};

export default LockTokens;
