import React from "react";
import "../css/progress.css"; // Assuming this is where your CSS is.

const ProgressBar = ({ current, max }) => {
  const percentage = ((current / max) * 100).toFixed(2); // Calculate the percentage
  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar"
        style={{ width: `${percentage > 100 ? 100 : percentage}%` }}
      ></div>
      <p className="progress-bar-text"> {percentage > 100 ? 100 : percentage}% <strong>Full</strong></p>
    </div>
  );
};

export default ProgressBar;
