import React, { useState } from "react";
import Web3 from "web3";
import { TOKEN_ABI, BATCH_ABI } from "./abi.js";
import Modal from "react-modal";
import "../css/presale.css";
import Navbar from "../widgets/NavBar";
//import { convertBigIntToFloat } from "../funcs/bigint.js";
import "../css/batchShi20.css";
import BigNumber from "bignumber.js"; // Import the BigNumber library
import RecipientManager from "../widgets/RecipientManager"; // Import the RecipientManager widget
import { connectWallet } from "../widgets/walletConnect";

const CONTRACT_ADDRESS = "0x1AD0D74967d8c91d88D88aA229a5DAf3e46538B6";

Modal.setAppElement("#root");
function BatchShi20() {
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [tokenAddress, setTokenAddress] = useState("");
    const [recipients, setRecipients] = useState([]);
    const [totalTokens, setTotalTokens] = useState(new BigNumber(0));
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [newRecipient, setNewRecipient] = useState({ address: "", amount: "" });
    const [error, setError] = useState("");
    const [balance, setBalance] = useState({ amount: new BigNumber(0), symbol: "" });
    const [allowance, setAllowance] = useState(new BigNumber(0));
    const [addressInput, setAddressInput] = useState(""); // Raw address input
    const [amountInput, setAmountInput] = useState(""); // Raw amount input

    const [txnHash, setTxnHash] = useState(""); // To store the transaction hash
    const [sendError, setSendError] = useState(""); // To store error messages

    const onConnectWallet = async () => {
        try {
            const { web3: web3Instance, account: userAccount } = await connectWallet(); // Call the connectWallet function

            setAccount(userAccount);

            const contractInstance = new web3Instance.eth.Contract(BATCH_ABI, CONTRACT_ADDRESS);
            setContract(contractInstance);
        } catch (error) {
            console.error("Error connecting wallet:", error);
        }
    };

    const handleTokenAddressChange = async (e) => {
        const input = e.target.value.trim();
        setTokenAddress(input);

        if (Web3.utils.isAddress(input)) {
            const web3 = new Web3(window.ethereum);
            try {
                const tokenInstance = new web3.eth.Contract(TOKEN_ABI, input);
                setTokenContract(tokenInstance);

                // Fetch token details
                const [balance, symbol, allowance] = await Promise.all([
                    tokenInstance.methods.balanceOf(account).call(),
                    tokenInstance.methods.symbol().call(),
                    tokenInstance.methods.allowance(account, CONTRACT_ADDRESS).call(),
                ]);

                setBalance({ amount: new BigNumber(balance), symbol });
                setAllowance(new BigNumber(allowance));
                setError("");
            } catch (error) {
                console.error("Error fetching token details. Ensure it's an ERC-20 contract:", error);
                setError("Invalid token address or unable to fetch token details.");
                setTokenContract(null);
                setBalance({ amount: new BigNumber(0), symbol: "" });
                setAllowance(new BigNumber(0));
            }
        } else {
            setError("Invalid token address.");
            setTokenContract(null);
            setBalance({ amount: new BigNumber(0), symbol: "" });
            setAllowance(new BigNumber(0));
        }
    };



    const handleInputParse = () => {
        const addressArray = addressInput.split(",").map((address) => address.trim());
        const amountArray = amountInput.split(",").map((amount) => amount.trim());

        // Check if lengths match and all addresses are valid
        if (addressArray.length !== amountArray.length) {
            alert("The number of addresses and amounts do not match.");
            return;
        }

        const parsedRecipients = [];
        let total = new BigNumber(0); // Initialize as BigNumber

        for (let i = 0; i < addressArray.length; i++) {
            const address = addressArray[i];
            let amount = amountArray[i];

            // Ensure amount is a valid number and greater than zero
            if (isNaN(amount) || Number(amount) <= 0) {
                alert(`Invalid amount at index ${i}.`);
                return;
            }

            // Convert the amount to wei (uint256) using BigNumber
            const amountWei = new BigNumber(Web3.utils.toWei(amount.toString(), "ether"));

            // Check if the address is valid
            if (Web3.utils.isAddress(address)) {
                parsedRecipients.push({ address, amount: amountWei.toString() }); // Store as string
                total = total.plus(amountWei); // Add the amount to the total using BigNumber
            } else {
                alert(`Invalid address at index ${i}.`);
                return;
            }
        }
        console.log("total: ", total.toString());  // Logs the total in wei (as string)
        setRecipients(parsedRecipients);
        setTotalTokens(total);
    };

    const handleModalSubmit = () => {
        const { address, amount } = newRecipient;
        const amountWei = new BigNumber(Web3.utils.toWei(amount.toString(), "ether"));
        if (Web3.utils.isAddress(address) && !amountWei.isNaN() && amountWei.gt(0)) {
            setRecipients([...recipients, { address, amount: amountWei }]);
            setTotalTokens(totalTokens.plus(amountWei));
            setNewRecipient({ address: "", amount: "" });
            setModalIsOpen(false);
        } else {
            alert("Invalid recipient address or amount.");
        }
    };

    const approveTokens = async () => {
        try {
            await tokenContract.methods.approve(CONTRACT_ADDRESS, totalTokens.toString()).send({ from: account });
            alert("Approval successful!");
            const newAllowance = await tokenContract.methods.allowance(account, CONTRACT_ADDRESS).call();
            setAllowance(new BigNumber(newAllowance));
        } catch (error) {
            console.error("Error approving tokens:", error);
            alert("Approval failed.");
        }
    };

    const sendTokens = async () => {
        if (recipients.length === 0 || !tokenContract) return;
        console.log(balance);
        if (totalTokens.gt(balance.amount)) {
            alert("Insufficient balance to complete the transfer.");
            return;
        }
        try {
            const addresses = recipients.map((r) => r.address);
            const amounts = recipients.map((r) => r.amount.toString());
            const tx = await contract.methods
                .batchTransferERC20(tokenAddress, addresses, amounts)
                .send({ from: account });

            alert("Transaction successful!");
            setTxnHash(tx.transactionHash); // Capture and store the transaction hash
            setRecipients([]);
            setTotalTokens(new BigNumber(0));
            setSendError("");
        } catch (error) {
            console.error("Error sending tokens:", error);
            setSendError("Transaction failed. Please try again.");
        }
    };

    const handleLogout = () => {
        setAccount("");
    };

    return (
        <div className="batch-shi20">
            <Navbar handleLogout={handleLogout} />
            <h1 className="title">Batch Shi20 Transfers</h1>

            {!account ? (
                <button className="login-btn" onClick={onConnectWallet}>
                    Connect Wallet
                </button>
            ) : (
                <div className="batch-shi20-logged">
                    <p>Connected as: {account}</p>
                    <h3>Token Contract Address</h3>
                    <input
                        className="input-field"
                        type="text"
                        placeholder="Enter token contract address"
                        value={tokenAddress}
                        onChange={handleTokenAddressChange}
                    />
                    {error && (
                        <h1 className="error">Invalid Contract</h1>
                    )}
                    <div className="ca">

                        {balance.amount.gt(0) && (
                            <p className="bal-allow">
                                Your Balance: {balance.amount.div(10 ** 18).toString()} {balance.symbol}
                                <br></br>
                                Current Allowance: {allowance.div(10 ** 18).toString()} {balance.symbol}
                            </p>
                        )}

                    </div>




                    {tokenContract && (
                        <>
                            <RecipientManager
                                recipients={recipients}

                                addressInput={addressInput}
                                setAddressInput={setAddressInput}
                                amountInput={amountInput}
                                setAmountInput={setAmountInput}
                                onParseInputs={handleInputParse}
                                addRecipient={() => setModalIsOpen(true)}
                                symbol={balance.symbol}
                            />

                            {recipients.length > 0 && (
                                <div className="txn-summary">
                                    {totalTokens.gt(balance.amount) && (
                                        <h1 className="error">Balance Overdrawn</h1>
                                    )}
                                    {txnHash && (
                                        <p className="txn-hash">
                                            Transaction Hash: <a href={`https://etherscan.io/tx/${txnHash}`} target="_blank" rel="noopener noreferrer">{txnHash}</a>
                                        </p>
                                    )}
                                    <p>Total Recipients: {recipients.length}</p>
                                    <p>
                                        Total Tokens: {totalTokens.div(10 ** 18).toString()} {balance.symbol}
                                    </p>
                                    {sendError && <p className="error-text">{sendError}</p>}
                                    {txnHash && (
                                        <p className="txn-hash">
                                            Transaction Hash: <a href={`https://etherscan.io/tx/${txnHash}`} target="_blank" rel="noopener noreferrer">{txnHash}</a>
                                        </p>
                                    )}
                                    {allowance.gte(totalTokens) ? (
                                        <button
                                            className="send-btn"
                                            onClick={sendTokens}
                                            disabled={recipients.length === 0}
                                        >
                                            Send Tokens
                                        </button>
                                    ) : (
                                        <button className="approve-btn" onClick={approveTokens}>
                                            Approve Tokens
                                        </button>
                                    )}

                                </div>

                            )}
                        </>
                    )}
                </div>
            )}

            {/* Modal for adding a recipient */}
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <h3>Add Recipient</h3>
                <input
                    type="text"
                    placeholder="Recipient Address"
                    value={newRecipient.address}
                    onChange={(e) => setNewRecipient({ ...newRecipient, address: e.target.value })}
                />
                <input
                    type="number"
                    placeholder="Amount"
                    value={newRecipient.amount}
                    onChange={(e) => setNewRecipient({ ...newRecipient, amount: e.target.value })}
                />
                <button onClick={handleModalSubmit}>Submit</button>
                <button onClick={() => setModalIsOpen(false)}>Close</button>
            </Modal>
        </div>
    );
}

export default BatchShi20;
