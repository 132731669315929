import BigNumber from "bignumber.js"; // Ensure BigNumber is imported
import { useState } from "react";
function RecipientManager({
    recipients,
    onParseInputs,
    addressInput,
    setAddressInput,
    amountInput,
    setAmountInput,
    addRecipient,
    symbol = "SHIDO"
}) {
    const [inputMethod, setInputMethod] = useState(""); // State for toggling input methods

    const textareaStyle = {
        width: "100%",
        height: "100px", // Fixed height
        marginBottom: "10px", // Space between textareas
        resize: "none", // Disable resizing
    };

    return (
        <div className="batch-container">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>Select Input Method</h3>
                <div className="dropdown-container">
                    <select
                        value={inputMethod}
                        onChange={(e) => setInputMethod(e.target.value)}
                        style={{
                            padding: "10px",
                            fontSize: "16px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            marginBottom: "20px",
                        }}
                    >
                        <option value="" disabled>
                            -- Select Method --
                        </option>
                        <option value="manual">Add Manually</option>
                        <option value="bulk">Add in Bulk</option>
                    </select>
                </div>

                <div className="input-method-container">
                    {inputMethod === "manual" && (
                        <div className="recipient-add">
                            <h5>Add Recipients One by One</h5>
                            <button className="add-btn" onClick={addRecipient}>
                                Add Recipient
                            </button>
                        </div>
                    )}

                    {inputMethod === "bulk" && (
                        <div className="alternate-input">
                            <h5>Input addresses/amounts separated by commas</h5>
                            <textarea
                                style={textareaStyle}
                                placeholder="Enter addresses (comma separated)"
                                value={addressInput}
                                onChange={(e) => setAddressInput(e.target.value)}
                            />
                            <textarea
                                style={textareaStyle}
                                placeholder="Enter amounts (comma separated)"
                                value={amountInput}
                                onChange={(e) => setAmountInput(e.target.value)}
                            />
                            <button className="parse-btn" onClick={onParseInputs}>
                                Parse Inputs
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="recipient-list">
                <h3>Recipient List</h3>
                {recipients.map((r, index) => (
                    <p key={index}>
                        {r.address} - {new BigNumber(r.amount).div(new BigNumber(10).pow(18)).toString()} {symbol}
                    </p>
                ))}
            </div>
        </div>
    );
}

export default RecipientManager;
