import React from "react";
import "../css/modal.css"; // Your CSS file for styling

const CustomModal = ({ isOpen, onClose, children, className = "" }) => {
    if (!isOpen) return null;

    return (
        <div className={`custom-modal-overlay ${className}`} onClick={onClose}>
            <div
                className="custom-modal-content"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <button className="custom-modal-close-btn" onClick={onClose}>
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

export default CustomModal;