import React, { useState } from "react";
import Web3 from "web3";
import CustomModal from "../widgets/modal.js";
import "../css/presale.css";
import Navbar from "../widgets/NavBar";
import "../css/batchShido.css";
import { BATCH_ABI } from "./abi.js";
import BigNumber from "bignumber.js";  // Import the BigNumber library
import RecipientManager from "../widgets/RecipientManager";
import { connectWallet } from "../widgets/walletConnect";

// Smart Contract Address (replace with actual contract address)
const CONTRACT_ADDRESS = "0x1AD0D74967d8c91d88D88aA229a5DAf3e46538B6";


function BatchShido() {
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [recipients, setRecipients] = useState([]);
    const [totalAmount, setTotalAmount] = useState(new BigNumber(0));
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [newRecipient, setNewRecipient] = useState({ address: "", amount: "" });
    const [balance, setBalance] = useState(new BigNumber(0));

    const [addressInput, setAddressInput] = useState("");
    const [amountInput, setAmountInput] = useState("");

    const onConnectWallet = async () => {
        try {
            const { web3: web3Instance, account: userAccount } = await connectWallet(); // Call the connectWallet function


            setAccount(userAccount);

            const contractInstance = new web3Instance.eth.Contract(BATCH_ABI, CONTRACT_ADDRESS);
            setContract(contractInstance);

            const balanceWei = await web3Instance.eth.getBalance(userAccount);
            setBalance(new BigNumber(balanceWei));
        } catch (error) {
            console.error("Error connecting wallet:", error);
        }
    };

    const handleModalSubmit = () => {
        const { address, amount } = newRecipient;
        const amountWei = new BigNumber(Web3.utils.toWei(amount.toString(), "ether"));
        if (Web3.utils.isAddress(address) && amountWei.gt(0)) {
            setRecipients([...recipients, { address, amount: amountWei }]);
            setTotalAmount(totalAmount.plus(amountWei));
            setNewRecipient({ address: "", amount: "" });
            setModalIsOpen(false);
        } else {
            alert("Invalid recipient address or amount.");
        }
    };

    const handleInputParse = () => {
        const addressArray = addressInput.split(",").map((address) => address.trim());
        const amountArray = amountInput.split(",").map((amount) => amount.trim());

        if (addressArray.length !== amountArray.length) {
            alert("The number of addresses and amounts do not match.");
            return;
        }

        const parsedRecipients = [];
        let total = new BigNumber(0);

        for (let i = 0; i < addressArray.length; i++) {
            const address = addressArray[i];
            const amount = amountArray[i];
            const amountWei = new BigNumber(Web3.utils.toWei(amount.toString(), "ether"));

            if (Web3.utils.isAddress(address) && amountWei.gt(0)) {
                parsedRecipients.push({ address, amount: amountWei });
                total = total.plus(amountWei);
            } else {
                alert(`Invalid data at index ${i}.`);
                return;
            }
        }

        setRecipients([...recipients, ...parsedRecipients]);
        setTotalAmount(totalAmount.plus(total));
    };

    const sendCoins = async () => {
        if (recipients.length === 0 || !contract) return;

        if (balance.lt(totalAmount)) {
            alert("Insufficient balance to complete the transfer.");
            return;
        }

        try {
            const addresses = recipients.map((r) => r.address);
            const amounts = recipients.map((r) => r.amount.toString());

            await contract.methods
                .batchTransferNative(addresses, amounts)
                .send({ from: account, value: totalAmount.toString() });

            alert("Transaction successful!");
            setRecipients([]);
            setTotalAmount(new BigNumber(0));
        } catch (error) {
            console.error("Error sending coins:", error);
        }
    };

    const handleLogout = () => {
        setAccount("");
    };

    return (
        <div className="batch-shido">
            <Navbar handleLogout={handleLogout} />
            <h1 className="title">Batch SHIDO Transfers</h1>
            {!account ? (
                <button className="login-btn" onClick={onConnectWallet}>
                    Connect Wallet
                </button>
            ) : (
                <div className="batch-shido-logged">
                    <p>Connected as: {account}</p>
                    <p>Your Balance: {balance.dividedBy(new BigNumber(10).pow(18)).toString()} SHIDO</p>
                    <RecipientManager
                        recipients={recipients}
                        onParseInputs={handleInputParse}
                        addressInput={addressInput}
                        setAddressInput={setAddressInput}
                        amountInput={amountInput}
                        setAmountInput={setAmountInput}
                        addRecipient={() => setModalIsOpen(true)}
                    />
                    {recipients.length > 0 && (
                        <div className="txn-summary">
                            {totalAmount.gt(balance) && <h1 className="error">Balance Overdrawn</h1>}
                            <p>Total Recipients: {recipients.length}</p>
                            <p>Total Amount: {totalAmount.dividedBy(new BigNumber(10).pow(18)).toString()} SHIDO</p>
                            <button
                                className="send-btn"
                                onClick={sendCoins}
                                disabled={totalAmount.gt(balance)}
                            >
                                Send Coins
                            </button>
                        </div>
                    )}
                </div>
            )}
            <CustomModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} onRequestClose={() => setModalIsOpen(false)}>
                <h3>Add Recipient</h3>
                <input
                    type="text"
                    placeholder="Recipient Address"
                    value={newRecipient.address}
                    onChange={(e) => setNewRecipient({ ...newRecipient, address: e.target.value })}
                />
                <input
                    type="number"
                    placeholder="Amount"
                    value={newRecipient.amount}
                    onChange={(e) => setNewRecipient({ ...newRecipient, amount: e.target.value })}
                />
                <button onClick={handleModalSubmit}>Submit</button>
                <button onClick={() => setModalIsOpen(false)}>Close</button>
            </CustomModal>

        </div>
    );
}

export default BatchShido;
