import React, { useState, useEffect, useCallback } from "react";

import "../css/staking.css"
import "../css/FeeTable.css"
import { TOKEN_ABI } from "../config.js";
import ProgressBar from "../widgets/progress";
import { convertBigIntToFloat } from "../funcs/bigint";
import Countdown from "../widgets/countdown"; // Adjust the import path as necessary
import Navbar from "../widgets/NavBar";
import { connectWallet } from "../widgets/walletConnect"; // Assuming your connectWallet function is in this file


const CONTRACT_ADDRESS = "0xbf2019c320AD99F7A84664c72599D772C225eF62";
const CONTRACT_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_stakingToken",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_apy",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_maxStakeable",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_startTime",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_duration",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_blacklistContract",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_stakeFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_claimFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_unstakeFee",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ReentrancyGuardReentrantCall",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "ExcessRewardsWithdrawn",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "FeesWithdrawn",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "ForceUnstaked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "requestedReward",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "availableReward",
                "type": "uint256"
            }
        ],
        "name": "InsufficientRewards",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "RewardsAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "fee",
                "type": "uint256"
            }
        ],
        "name": "RewardsClaimed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "fee",
                "type": "uint256"
            }
        ],
        "name": "Staked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "fee",
                "type": "uint256"
            }
        ],
        "name": "Unstaked",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "accumulatedFees",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "addRewards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "apy",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "blacklistContract",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "claimFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "claimRewards",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "endTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "forceUnstake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getClaimableRewards",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getFees",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_stakeFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_claimFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_unstakeFee",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getRemainingRewards",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxStakeable",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "requiredRewardAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rewardBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "rewards",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "stake",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "stakeFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "stakes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "lastUpdate",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "stakingToken",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "startTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalStaked",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unstake",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unstakeFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawExcessRewards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawFees",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

const TOKEN_ADDRESS = "0x2835Ad9a421C14E1C571a5Bb492B86b7E8f5873A"; // Replace with your ERC20 token address



function StakingOG() {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState("");
    const [contract, setContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [stakeAmount, setStakeAmount] = useState("");
    const [maxStakeable, setMaxStakeable] = useState("");
    const [userStake, setUserStake] = useState(0);
    const [totalStaked, setTotalStaked] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [allowance, setAllowance] = useState(0);
    const [rewardPool, setRewardPool] = useState(0);
    const [statusMessage, setStatusMessage] = useState("");
    const [apy, setApy] = useState("8");
    const [startTime, setStartTime] = useState("");
    const [end, setEnd] = useState("");
    const [rewardAmount, setRewardAmount] = useState(""); // Input for adding rewards
    const [claimableRewards, setClaimableRewards] = useState(""); // Input for rewards
    const [stakingHasStarted, setStakingHasStarted] = useState(false); // Check if staking has started
    const [remainingRewards, setRemainingRewards] = useState(""); // Remaining rewards needed
    const [accumulatedFees, setAccumulatedFees] = useState("");
    const [stakeFee, setStakeFee] = useState("");
    const [claimFee, setClaimFee] = useState("");
    const [unstakeFee, setUnstakeFee] = useState("");
    const [currentMaxStakeable, setCurrentMaxStakeable] = useState("");


    const ADMIN_ADDRESS = "0x062f444E491016dd87a46009798956C910108E40";
    const isAdmin = account.toLowerCase() === ADMIN_ADDRESS.toLowerCase(); // Check if logged-in account is admin

    const onConnectWallet = async () => {
        if (!window.ethereum) {
            alert("MetaMask not detected! Please install MetaMask to use this app.");
            return;
        }

        try {
            const { web3: web3Instance, account: userAccount } = await connectWallet(); // Call the connectWallet function
            setWeb3(web3Instance);
            setAccount(userAccount);

            // Initialize contract instances
            const stakingContract = new web3Instance.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
            setContract(stakingContract);

            const token = new web3Instance.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);
            setTokenContract(token);

            console.log("Token contract initialized:", token);

            setStatusMessage("Wallet connected successfully!");
        } catch (error) {
            console.error("Error during wallet connection:", error);

            // Handle specific error codes for chain switching
            if (error.code === 4902) {
                alert("The required network is not available in your wallet. Please add it manually.");
            } else if (error.code === 4001) {
                alert("Connection request was rejected by the user.");
            } else {
                alert("Failed to connect wallet. Please check your settings.");
            }
        }
    };


    const fetchUserData = useCallback(async () => {
        if (contract && tokenContract && account) {
            try {
                // Check if the account address is valid
                if (!web3.utils.isAddress(account)) {
                    console.error("Invalid account address");
                    return;
                }

                console.log("Fetching user data for account:", account);

                // Fetch contract and token data
                const balance = await tokenContract.methods.balanceOf(account).call();
                console.log("User Balance (raw):", balance);



                const totalStaked = await contract.methods.totalStaked().call();
                console.log("Total Staked (raw):", totalStaked);

                const maxStakeable = await contract.methods.maxStakeable().call();
                console.log("Max Stakeable (raw):", maxStakeable);

                const allowance = await tokenContract.methods.allowance(account, CONTRACT_ADDRESS).call();
                console.log("Token Allowance (raw):", allowance);

                const rewards = await contract.methods.getClaimableRewards(account).call();
                console.log("Claimable Rewards (raw):", rewards);

                const apy = await contract.methods.apy().call();
                console.log("APY (raw):", apy);

                const startTime = await contract.methods.startTime().call();
                console.log("Staking Start Time (raw):", startTime);

                const end = await contract.methods.endTime().call();
                console.log("Staking End Time (raw):", end);

                const rewardPool = await contract.methods.rewardBalance().call();
                console.log("Reward Pool (raw):", rewardPool);

                const accumulatedFees = await contract.methods.accumulatedFees().call();
                console.log("Accumulated Fees (raw):", accumulatedFees);

                const stakeFee = await contract.methods.stakeFee().call();
                console.log("Stake Fee (raw):", stakeFee);

                const claimFee = await contract.methods.claimFee().call();
                console.log("Claim Fee (raw):", claimFee);

                const unstakeFee = await contract.methods.unstakeFee().call();
                console.log("Unstake Fee (raw):", unstakeFee);

                const remaining = await contract.methods.getRemainingRewards().call();
                console.log("Remaining Rewards Needed (raw):", remaining);

                const userStake = await contract.methods.stakes(account).call();
                console.log("User Stake (raw):", userStake);

                // Process and log converted values
                const userBalanceEther = web3.utils.fromWei(balance, "ether");
                console.log("User Balance (ETH):", userBalanceEther);

                const userStakeEther = web3.utils.fromWei(userStake.amount, "ether");
                console.log("User Stake (ETH):", userStakeEther);

                const totalStakedEther = web3.utils.fromWei(totalStaked, "ether");
                console.log("Total Staked (ETH):", totalStakedEther);

                const maxStakeableEther = web3.utils.fromWei(maxStakeable, "ether");
                console.log("Max Stakeable (ETH):", maxStakeableEther);

                const allowanceEther = web3.utils.fromWei(allowance, "ether");
                console.log("Token Allowance (ETH):", allowanceEther);

                const claimableRewardsEther = web3.utils.fromWei(rewards, "ether");
                console.log("Claimable Rewards (ETH):", claimableRewardsEther);

                const rewardPoolEther = web3.utils.fromWei(rewardPool, "ether");
                console.log("Reward Pool (ETH):", rewardPoolEther);

                const accumulatedFeesEther = web3.utils.fromWei(accumulatedFees, "ether");
                console.log("Accumulated Fees (ETH):", accumulatedFeesEther);

                const stakeFeeEther = web3.utils.fromWei(stakeFee, "ether");
                console.log("Stake Fee (ETH):", stakeFeeEther);

                const claimFeeEther = web3.utils.fromWei(claimFee, "ether");
                console.log("Claim Fee (ETH):", claimFeeEther);

                const unstakeFeeEther = web3.utils.fromWei(unstakeFee, "ether");
                console.log("Unstake Fee (ETH):", unstakeFeeEther);

                const remainingRewardsEther = web3.utils.fromWei(remaining, "ether");
                console.log("Remaining Rewards Needed (ETH):", remainingRewardsEther);

                // Set state with converted values
                setUserBalance(userBalanceEther);
                setUserStake(userStakeEther);
                setTotalStaked(totalStakedEther);
                setMaxStakeable(maxStakeableEther);
                setAllowance(allowanceEther);
                setClaimableRewards(claimableRewardsEther);
                setApy(apy);
                setStartTime(startTime);
                setEnd(end);
                setRewardPool(rewardPoolEther);
                setAccumulatedFees(accumulatedFeesEther);
                setStakeFee(stakeFeeEther);
                setClaimFee(claimFeeEther);
                setUnstakeFee(unstakeFeeEther);
                setRemainingRewards(remainingRewardsEther);
                setCurrentMaxStakeable(web3.utils.fromWei(maxStakeable - totalStaked, "ether"));

                // Log additional computed values
                console.log("Current Max Stakeable (ETH):", web3.utils.fromWei(maxStakeable - totalStaked, "ether"));

                // Check if staking has started
                if (startTime <= Math.floor(Date.now() / 1000)) {
                    console.log("Staking has started.");
                    setStakingHasStarted(true);
                } else {
                    console.log("Staking has not started yet.");
                    setStakingHasStarted(false);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setStatusMessage("Failed to fetch user data.");
            }
        }
    }, [contract, tokenContract, account, web3]);


    useEffect(() => {
        if (contract && tokenContract && account) {
            fetchUserData();
        }
    }, [contract, tokenContract, account, fetchUserData]);

    // Approve tokens
    const handleApprove = async (minValue) => {
        try {
            const maxApproval = web3.utils.toWei(minValue, "ether"); // Arbitrary high value for max approval
            await tokenContract.methods.approve(CONTRACT_ADDRESS, maxApproval).send({ from: account });
            setStatusMessage("Tokens approved successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to approve tokens.");
        }
    };
    const handleLogout = () => {
        setAccount("");
        web3.setProvider(null); // Resets Web3's connection

    };


    // Stake tokens
    const handleStake = async () => {
        if (!stakeAmount || parseFloat(stakeAmount) <= 0) {
            setStatusMessage("Please enter a valid amount to stake.");
            return;
        }

        try {
            const amountInWei = web3.utils.toWei(stakeAmount, "ether");

            // Call the stake function
            await contract.methods.stake(amountInWei).send({ from: account, value: web3.utils.toWei(stakeFee, "ether") });
            setStatusMessage("Tokens staked successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to stake tokens.");
        }
    };

    // Unstake tokens
    const handleClaim = async () => {
        try {
            await contract.methods.claimRewards().send({ from: account, value: web3.utils.toWei(claimFee, "ether") });
            setStatusMessage("Tokens claimed successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to claim tokens.");
        }
    };

    const handleUnstake = async () => {
        try {
            await contract.methods.unstake().send({ from: account, value: web3.utils.toWei(unstakeFee, "ether") });
            setStatusMessage("Tokens unstaked successfully!");
            fetchUserData();
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to unstake tokens.");
        }
    };

    // Function to add rewards to the staking pool
    const handleAddRewards = async () => {
        if (!rewardAmount || parseFloat(rewardAmount) <= 0) {
            setStatusMessage("Enter a valid reward amount.");
            return;
        }

        try {
            const amountInWei = web3.utils.toWei(rewardAmount, "ether");

            // Call addRewards function in the contract
            await contract.methods.addRewards(amountInWei).send({ from: account });
            setStatusMessage("Rewards added successfully!");
            fetchUserData(); // Refresh contract state after updating rewards
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to add rewards.");
        }
    };

    // Function to withdraw excess rewards after the staking period
    const handleWithdrawExcessRewards = async () => {
        try {
            // Call withdrawExcessRewards function in the contract
            await contract.methods.withdrawExcessRewards().send({ from: account });
            setStatusMessage("Excess rewards withdrawn successfully!");
            fetchUserData(); // Refresh contract state after withdrawal
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to withdraw excess rewards.");
        }
    };

    const handleWithdrawFees = async () => {
        try {
            // Call withdrawExcessRewards function in the contract
            await contract.methods.withdrawFees().send({ from: account });
            setStatusMessage("Fees withdrawn successfully!");
            fetchUserData(); // Refresh contract state after withdrawal
        } catch (error) {
            console.error(error);
            setStatusMessage("Failed to withdraw excess rewards.");
        }
    };

    function FeeTable({ stakeFee, claimFee, unstakeFee }) {
        return (
            <div className="fee-table">
                <h4>Fees</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Stake</th>
                            <th>Claim Rewards</th>
                            <th>Unstake</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Fee (SHIDO)</td>
                            <td>{stakeFee}</td>
                            <td>{claimFee}</td>
                            <td>{unstakeFee}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="staking" style={{ padding: "2rem", textAlign: "center" }}>
            <Navbar handleLogout={handleLogout} />
            <h1 className="title" id="staking-title">KIDDO Staking</h1>
            {!account ? (
                <button className="connect-button" onClick={onConnectWallet}>
                    Connect Wallet
                </button>
            ) : (
                <>
                    <p><strong>Connected as</strong>: {account}</p>
                    <ProgressBar current={totalStaked} max={maxStakeable} />
                    <div className="card-container">
                        {/* Staking Stats Card */}
                        <div className="card">
                            <h3>Staking Pool Stats</h3>
                            {!stakingHasStarted ? (
                                <>
                                    <Countdown endTimestamp={parseInt(startTime)} text={"Staking Starts In"} />
                                </>
                            ) : (
                                <>
                                    <Countdown endTimestamp={parseInt(end)} />
                                </>

                            )}
                            <p><strong>Max Stakeable:</strong> {maxStakeable}</p>
                            <p><strong>APY:</strong> {convertBigIntToFloat(apy, 0)}%</p>
                            <p><strong>Total Tokens Staked:</strong> {totalStaked}</p>
                            <p><strong>KIDDO from Max Stakeable:</strong> {currentMaxStakeable}</p>

                            <p><strong>Reward Pool:</strong> {rewardPool}</p>
                        </div>
                        {/* User Stats Card */}
                        <div className="card">
                            <h3>Your Stats</h3>
                            <p><strong>Your Token Balance:</strong> {userBalance}</p>
                            <p><strong>Your Staked Tokens:</strong> {userStake}</p>
                            <p><strong>Your Rewards:</strong> {claimableRewards}</p>
                        </div>
                    </div>

                    {/* Action Card */}
                    <div className="card" id="input">
                        <h3>Actions</h3>
                        <FeeTable stakeFee={stakeFee} claimFee={claimFee} unstakeFee={unstakeFee} />
                        {stakingHasStarted ? (
                            <>
                                <div style={{ margin: "1rem 0" }}>
                                    <input
                                        type="number"
                                        placeholder="Enter amount to stake"
                                        value={stakeAmount}
                                        onChange={(e) => setStakeAmount(e.target.value)}
                                        className="stake-input"
                                    />
                                    {parseFloat(allowance) >= parseFloat(stakeAmount) ? (
                                        <button className="action-button" onClick={handleStake}>Stake</button>
                                    ) : (
                                        <button className="action-button" onClick={() => handleApprove(stakeAmount)}>Approve</button>
                                    )}
                                </div>
                                <div className="button-case">
                                    <button className="action-button" onClick={handleClaim}>Claim Rewards</button>
                                    <button className="action-button" onClick={handleUnstake}>Unstake</button>
                                    <button className="action-button" onClick={fetchUserData}>Refresh</button>
                                </div>
                            </>
                        ) : (
                            <p>Staking has not started yet. Please wait for the start time.</p>
                        )}
                    </div>

                    {/* Admin Panel */}
                    {isAdmin && (
                        <div className="card admin-panel">
                            <h3>Admin Panel</h3>
                            <p><strong>Remaining Rewards Needed:</strong> {remainingRewards} KIDDO</p>
                            <p><strong>Accumulated Fees:</strong> {accumulatedFees} SHIDO</p>

                            <div style={{ margin: "1rem 0" }}>
                                <input
                                    type="number"
                                    placeholder="Enter reward amount to add"
                                    value={rewardAmount}
                                    onChange={(e) => setRewardAmount(e.target.value)}
                                    className="stake-input"
                                />

                                {parseFloat(allowance) >= parseFloat(rewardAmount) ? (
                                    <button className="action-button" onClick={handleAddRewards}>
                                        Add Rewards
                                    </button>
                                ) : (
                                    <button className="action-button" onClick={() => handleApprove(rewardAmount)}>Approve</button>
                                )}

                            </div>
                            <div>
                                <button
                                    className="action-button"
                                    onClick={handleWithdrawExcessRewards}
                                >
                                    Withdraw Excess Rewards
                                </button>
                                <button
                                    className="action-button"
                                    onClick={handleWithdrawFees}
                                >
                                    Withdraw Fees
                                </button>
                            </div>

                        </div>
                    )}

                </>
            )
            }

            {/* Status message */}
            {statusMessage && <p className="status-message">{statusMessage}</p>}
        </div >
    );

}

export default StakingOG;