import React, { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import "../css/TagBattleWidget.css";
import { TAG, TOKEN_ABI } from "../config.js";
import Navbar from "../widgets/NavBar";
import Modal from "../widgets/ModalTag.js";
import { convertBigIntToFloat } from "../funcs/bigint.js";
import { connectWallet } from "../widgets/walletConnect";

const contractABI = TAG;
const contractAddress = "0x5cA771A8cB1a51251174A9dfC2f06182d84914F6";

const TagBattleWidget = () => {
    const [web3, setWeb3] = useState(new Web3(window.ethereum));
    const [account, setAccount] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [contract, setContract] = useState(null);
    const [kiddoContract, setKiddoContract] = useState(null);
    const [currentMessage, setCurrentMessage] = useState("");
    const [currentLikes, setCurrentLikes] = useState("");
    const [newMessage, setNewMessage] = useState("");
    const [shidoFee, setShidoFee] = useState("");
    const [kiddoFee, setKiddoFee] = useState("");
    const [shidoLikeFee, setShidoLikeFee] = useState("");
    const [kiddoLikeFee, setKiddoLikeFee] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("KIDDO");
    const [allowance, setAllowance] = useState("0");
    const [kiddoBalance, setKiddoBalance] = useState("0");
    const [shidoBalance, setShidoBalance] = useState("0");
    const [cooldownDuration, setCooldownDuration] = useState("0");
    const [cooldownEndTime, setCooldownEndTime] = useState("0");
    const [cooldownRemaining, setCooldownRemaining] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalAction, setModalAction] = useState(() => { });
    const [totalMessages, setTotalMessages] = useState("0");
    const [totalLikes, setTotalLikes] = useState("0");
    const [totalKiddoEarned, setTotalKiddoEarned] = useState("0");
    const [totalShidoEarned, setTotalShidoEarned] = useState("0");
    //const [myMessages, setMyMessages] = useState("0");
    //const [allMyLikes, setAllMyLikes] = useState("0");
    //const [mySeasonLikes, setMySeasonalLikes] = useState("0");
    const [currentSeason, setCurrentSeason] = useState("0");
    const [currentSender, setCurrentSender] = useState("");
    const [creatorSharePercentage, setCreatorSharePercentage] = useState("50");
    const [hasLiked, setHasLiked] = useState(false);

    const onConnectWallet = async () => {
        try {
            const { web3: web3Instance, account: connectedAccount } = await connectWallet(); // Call the connectWallet function
            console.log(connectedAccount);

            setAccount(connectedAccount); // Update state asynchronously
            setWeb3(web3Instance);

            // Initialize the Tag Battle contract
            const tagBattleContract = new web3Instance.eth.Contract(
                contractABI,
                contractAddress
            );
            setContract(tagBattleContract); // Update state asynchronously

            // Fetch the Kiddo Token contract address and initialize it
            const kiddoTokenAddress = await tagBattleContract.methods.kiddoToken().call();
            const kiddoContractInstance = new web3Instance.eth.Contract(
                TOKEN_ABI,
                kiddoTokenAddress
            );
            setKiddoContract(kiddoContractInstance); // Update state asynchronously

            // Pass the correct account to fetchContractData
            await fetchContractData(
                web3Instance,
                tagBattleContract,
                kiddoContractInstance,
                connectedAccount // Use the correct variable here
            );
        } catch (error) {
            console.error("Initialization error:", error);
        }
    };



    const fetchContractData = useCallback(
        async (web3Instance, tagBattleContract, kiddoContractInstance, account) => {
            try {
                // Fetch static data once and cache it
                const [shidoLikeFee, kiddoLikeFee, shidoFee, kiddoFee] = await Promise.all([
                    tagBattleContract.methods.likeShidoFee().call(),
                    tagBattleContract.methods.likeKiddoFee().call(),
                    tagBattleContract.methods.shidoFee().call(),
                    tagBattleContract.methods.kiddoFee().call(),
                ]);

                // Set static data (doesn't change often)
                setShidoLikeFee(web3Instance.utils.fromWei(shidoLikeFee, "ether"));
                setKiddoLikeFee(web3Instance.utils.fromWei(kiddoLikeFee, "ether"));
                setShidoFee(web3Instance.utils.fromWei(shidoFee, "ether"));
                setKiddoFee(web3Instance.utils.fromWei(kiddoFee, "ether"));

                // Fetch dynamic data in parallel
                console.log(account);
                const [
                    owner,
                    updatedMessage,
                    cooldownDuration,
                    cooldownEndTime,
                    currentLikes,
                    totalMessages,
                    totalLikes,
                    totalKiddoEarned,
                    totalShidoEarned,
                    currentSeason,
                    currentSender,
                    creatorSharePercentage,
                    hasLiked,
                ] = await Promise.all([
                    tagBattleContract.methods.owner().call(),
                    tagBattleContract.methods.currentMessage().call(),
                    tagBattleContract.methods.cooldownDuration().call(),
                    tagBattleContract.methods.cooldownEndTime().call(),
                    tagBattleContract.methods.currentLikes().call(),
                    tagBattleContract.methods.totalMessages().call(),
                    tagBattleContract.methods.totalLikes().call(),
                    tagBattleContract.methods.totalKiddoEarned().call(),
                    tagBattleContract.methods.totalShidoEarned().call(),
                    tagBattleContract.methods.currentSeason().call(),
                    tagBattleContract.methods.currentSender().call(),
                    tagBattleContract.methods.creatorSharePercentage().call(),
                    tagBattleContract.methods.hasLiked(account).call(),
                ]);

                // Set dynamic data
                setIsOwner(account.toLowerCase() === owner.toLowerCase());
                setCurrentMessage(updatedMessage);
                setCooldownDuration(cooldownDuration);
                setCooldownEndTime(cooldownEndTime);
                setCurrentLikes(currentLikes);
                setTotalMessages(totalMessages);
                setTotalLikes(totalLikes);
                setTotalKiddoEarned(totalKiddoEarned);
                setTotalShidoEarned(totalShidoEarned);
                setCurrentSeason(currentSeason);
                setCurrentSender(currentSender);
                setCreatorSharePercentage(creatorSharePercentage);
                setHasLiked(hasLiked);

                // Fetch user-specific data (balances, allowances)
                await fetchUserData(web3Instance, kiddoContractInstance, account); // Pass the correct account here
            } catch (error) {
                console.error("Error fetching contract data:", error);
            }
        },
        [] // Ensure this function only depends on static values
    );


    const fetchUserData = async (web3Instance, kiddoContractInstance, account) => {
        try {
            const [shidoBalance, kiddoBalance, allowance] = await Promise.all([
                web3Instance.eth.getBalance(account),
                kiddoContractInstance.methods.balanceOf(account).call(),
                kiddoContractInstance.methods.allowance(account, contractAddress).call(),
            ]);
            const shidoFull = web3Instance.utils.fromWei(shidoBalance, "ether");
            const kiddoFull = web3Instance.utils.fromWei(kiddoBalance, "ether");
            setShidoBalance(parseFloat(shidoFull).toFixed(2));
            setKiddoBalance(parseFloat(kiddoFull).toFixed(2));
            setAllowance(web3Instance.utils.fromWei(allowance, "ether"));
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };


    const openModal = (title, action) => {
        console.log("Open Modal");
        setModalTitle(title);
        setModalAction(() => action);
        setIsModalOpen(true);
        console.log(isModalOpen);
    };

    const handleApprove = async () => {
        if (!kiddoContract || !kiddoFee) return;

        try {
            console.log(isOwner);
            await kiddoContract.methods
                .approve(contractAddress, web3.utils.toWei(kiddoFee, "ether"))
                .send({ from: account });
            alert("Approval successful!");

            const updatedAllowance = await kiddoContract.methods
                .allowance(account, contractAddress)
                .call();
            setAllowance(web3.utils.fromWei(updatedAllowance, "ether"));
        } catch (error) {
            console.error("Approval error:", error);
            alert("Failed to approve KIDDO tokens.");
        }
    };

    const handleSubmitMessage = async () => {
        if (!newMessage) return alert("Please enter a message.");
        try {
            if (selectedPaymentMethod === "shido") {
                await contract.methods.submitMessageWithShido(newMessage).send({ from: account, value: web3.utils.toWei(shidoFee, "ether"), })
                    .on("receipt", async (receipt) => {
                        // The transaction is confirmed on-chain.
                        // Let's refetch data here:
                        await fetchContractData(web3, contract, kiddoContract, account);
                        alert("Message submitted with SHIDO!");
                    });
            } else if (selectedPaymentMethod === "kiddo") {
                if (parseFloat(allowance) < parseFloat(kiddoFee)) {
                    return alert("Please approve KIDDO tokens before submitting the message.");
                }

                await contract.methods.submitMessageWithKiddo(newMessage).send({ from: account })
                    .on("receipt", async (receipt) => {
                        // The transaction is confirmed on-chain.
                        // Let's refetch data here:
                        await fetchContractData(web3, contract, kiddoContract, account);
                        alert("Message submitted with KIDDO!");
                    });
            } else {
                alert("Please select a payment method.");
            }

            const updatedMessage = await contract.methods.currentMessage().call();
            setCurrentMessage(updatedMessage);
        } catch (error) {
            console.error("Message submission error:", error);
            alert("Failed to submit the message.");
        }
        setIsModalOpen(false);
    };

    const handleLikeMessage = async () => {
        try {
            if (selectedPaymentMethod === "shido") {
                await contract.methods
                    .likeMessageWithShido()
                    .send({
                        from: account,
                        value: web3.utils.toWei(shidoLikeFee, "ether"),
                    })
                    .on("receipt", async () => {
                        // Refresh data
                        await fetchContractData(web3, contract, kiddoContract, account);
                        alert("Message liked with SHIDO!");
                    });
            } else if (selectedPaymentMethod === "kiddo") {
                if (parseFloat(allowance) < parseFloat(kiddoLikeFee)) {
                    return alert("Please approve KIDDO tokens before liking the message.");
                }
                await contract.methods
                    .likeMessageWithKiddo()
                    .send({ from: account })
                    .on("receipt", async () => {
                        // Refresh data
                        await fetchContractData(web3, contract, kiddoContract, account);
                        alert("Message liked with KIDDO!");
                    });
            } else {
                alert("Please select a payment method.");
            }
        } catch (error) {
            console.error("Error liking the message:", error);
            alert("Failed to like the message.");
        }
        setIsModalOpen(false);
    };





    const renderBalances = () => (
        <div className="balances">
            <h3 className="balances-title"><strong>Balances</strong></h3>
            <div className="balances-table">
                <div className="balances-header">
                    <div className="balances-cell"><strong>SHIDO</strong></div>
                    <div className="balances-cell"><strong>KIDDO</strong></div>
                </div>
                <div className="balances-row">
                    <div className="balances-cell">{shidoBalance}</div>
                    <div className="balances-cell">{kiddoBalance}</div>
                </div>
            </div>
        </div>
    );



    const renderSubmitMessage = () => (
        <div className="submit-message">
            <h3>Submit a New Message</h3>
            {renderDetails()}
            <input
                type="text"
                placeholder="Write your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
            />
            {cooldownRemaining > 0 && (
                <p style={{ color: "red" }}>
                    Cooldown active: {cooldownRemaining} sec
                </p>
            )}
            <button
                onClick={() => openModal("Submit Message", handleSubmitMessage)}
                disabled={cooldownRemaining > 0}
            >
                Submit Message
            </button>
        </div>
    );


    const renderDetails = () => (
        <div className="details">
            <p>Your message on the SHIDO chain, displayed for the world to see!</p>
            <p>Use KIDDO for cooldown protection and earn likes to share {creatorSharePercentage.toString()}% of the fees.</p>
            <p>Cooldown Duration: <strong>{cooldownDuration.toString()} on SHIDO likes. {(convertBigIntToFloat(cooldownDuration, 0) * 2).toString()} seconds on KIDDO Likes and Messages</strong></p>

        </div>
    );
    const renderFees = () => (
        <div className="details">
            <div className="fee-table">
                <div className="fee-header">
                    <div className="fee-cell"><strong>Fees</strong></div>
                    <div className="fee-cell">SHIDO</div>
                    <div className="fee-cell">KIDDO</div>
                </div>
                <div className="fee-row">
                    <div className="fee-cell">Message</div>
                    <div className="fee-cell">{shidoFee}</div>
                    <div className="fee-cell">{kiddoFee}</div>
                </div>
                <div className="fee-row">
                    <div className="fee-cell">Like</div>
                    <div className="fee-cell">{shidoLikeFee}</div>
                    <div className="fee-cell">{kiddoLikeFee}</div>
                </div>
            </div>
        </div>
    );

    const renderMessageBoxWithLike = () => (
        <div className="message-box-with-like">
            <div className="message-box">
                <p><strong>{currentMessage || "No messages yet. Be the first!"}</strong></p>
                <p id="sender">By: <b>{currentSender}</b></p>
                <p>Likes: <strong>{currentLikes.toString()}</strong></p>
                {currentMessage && hasLiked && (
                    <div className="like-message">
                        <p style={{ color: "white" }}>You liked this message!</p>
                    </div>
                )}
            </div>

            {currentMessage && !hasLiked && (
                <div className="like-message">
                    <button onClick={() => openModal("Like Message", handleLikeMessage)}>
                        Like Message
                    </button>
                </div>
            )}

            {/* If the message exists and user already liked it, show text */}

        </div>
    );

    const renderStats = () => (
        <div className="stats-box">
            <h3>Stats</h3>
            <ul>
                <li>Total Messages: {totalMessages.toString()}</li>
                <li>Total Likes: {totalLikes.toString()}</li>
                <li>Total Kiddo Raised: {convertBigIntToFloat(totalKiddoEarned)}</li>
                <li>Total Shido Raised: {convertBigIntToFloat(totalShidoEarned)}</li>
                <li>Current Season: {currentSeason.toString()}</li>
            </ul>
        </div>
    );

    const handleLogout = () => {
        setAccount("");
    };



    useEffect(() => {
        const interval = setInterval(() => {
            const now = Math.floor(Date.now() / 1000); // Current UNIX time in seconds
            const diff = convertBigIntToFloat(cooldownEndTime, 0) - now;
            setCooldownRemaining(diff > 0 ? diff : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [cooldownEndTime]);


    return (
        <div className="tag-battle-widget">
            <Navbar handleLogout={handleLogout} />
            <h1 className="title">Tag Battle</h1>

            {!account ? (
                <div className="login-container">
                    <button
                        onClick={onConnectWallet}
                    >
                        Login with MetaMask
                    </button>
                </div>
            ) : (
                <>
                    {/* Top Section */}
                    <div className="top-container">
                        {/* Message with Like Button */}
                        <div className="message-section">
                            {renderMessageBoxWithLike()}
                            {renderStats()}
                        </div>
                        {/* Balances */}
                    </div>

                    {/* Bottom Section */}
                    <div className="bottom-container">
                        <div className="actions-container">
                            {renderSubmitMessage()} {/* Submit Message Section */}
                        </div>
                        <div className="details-container">
                            {renderBalances()}
                            {renderFees()} {/* Cooldown Info, Fees, etc. */}
                        </div>
                    </div>
                </>
            )}

            {/* Modal */}
            {isModalOpen && (
                <Modal
                    title={modalTitle}
                    onClose={() => setIsModalOpen(false)}
                    onAction={modalAction}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    requiresApproval={
                        selectedPaymentMethod === "kiddo" &&
                        parseFloat(allowance) < parseFloat(kiddoFee)
                    }
                    onApprove={handleApprove}
                />
            )}
        </div>
    );



};

export default TagBattleWidget;
