import Web3 from "web3";
export const connectWallet = async () => {
    if (!window.ethereum) {
        alert("MetaMask not detected! Please install MetaMask to use this app.");
        return;
    }

    try {
        const targetChainId = "0x2330"; // Chain ID for 9008 in hexadecimal

        // Ensure the user is on the correct chain
        const currentChainId = await window.ethereum.request({ method: "eth_chainId" });
        if (currentChainId !== targetChainId) {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: targetChainId }],
            });
        }

        // Request wallet access
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3Instance = new Web3(window.ethereum);
        const userAccount = accounts[0];
        // Sign a message
        const signature = await window.ethereum.request({
            method: "personal_sign",
            params: ["ShidoKid loves you <3", userAccount],
        });

        console.log("Signature:", signature);


        return { web3: web3Instance, account: userAccount };
    } catch (error) {
        console.error("Error during wallet connection:", error);

        if (error.code === 4902) {
            alert("The required network is not available in your wallet. Please add it manually.");
        } else if (error.code === 4001) {
            alert("Connection request was rejected by the user.");
        } else {
            alert("Failed to connect wallet. Please check your settings.");
        }
    }
};
