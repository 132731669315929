import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreSale from "./pages/preSale";
import Home from "./pages/home"
import Tools from "./pages/tools"
import BatchShi20 from "./pages/batchShi20"
import BatchShido from "./pages/batchShido"
import StashWidget from "./pages/stash.js"
//import NFTWidget from "./pages/nft.js"
import TagBattleWidget from "./pages/TagBattleWidget.js";
import Staking from "./pages/staking.js";
import StakingOG from "./pages/stakingOG.js";
import ERC20Widget from "./pages/ERC20.js";
import LockTokens from "./pages/StashNew.js"
import "./css/App.css"


function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/seed-round" element={<PreSale />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/tools/batch-shi20" element={<BatchShi20 />} />
          <Route path="/tools/batch-shido" element={<BatchShido />} />
          <Route path="/stash" element={<StashWidget />} />
          {/*<Route path="/nft" element={<NFTWidget />} />
          
          {/*<Route path="/nft/:id" element={<NFTView />} />*/}
          <Route path="/tag" element={<TagBattleWidget />} />
          <Route path="/erc20" element={<ERC20Widget />} />
          <Route path="/" element={<Home />} />
          <Route path="/staking" element={<StakingOG />} />
          <Route path="/stake" element={<Staking />} />
          <Route path="/test" element={<LockTokens />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
