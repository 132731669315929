import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { convertBigIntToFloat } from "../funcs/bigint.js";
import Navbar from "../widgets/NavBar";
import "../css/StashDisplay.css"
import "../css/StashDisplay.css"
const stashAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "locker",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "unlockTime",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "label",
                "type": "string"
            }
        ],
        "name": "TokensLocked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "locker",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "TokensUnlocked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "locker",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "LockRemoved",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "tokenCreator",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_duration",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_label",
                "type": "string"
            }
        ],
        "name": "lockTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_index",
                "type": "uint256"
            }
        ],
        "name": "unlockTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_locker",
                "type": "address"
            }
        ],
        "name": "getLocks",
        "outputs": [
            {
                "internalType": "struct Stash.LockedToken[]",
                "name": "",
                "type": "tuple[]",
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "unlockTime",
                        "type": "uint256"
                    },
                    {
                        "internalType": "string",
                        "name": "label",
                        "type": "string"
                    }
                ]
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]; // Adjust the path to your ABI file

const StashWidget = () => {
    const [locks, setLocks] = useState([]);
    const [owner, setOwner] = useState("");
    const [loading, setLoading] = useState(true);
    const stashAddress = "0x5B73743d6e99E911e6C412C0BcA9a702475F0595";

    useEffect(() => {
        const fetchStashInfo = async () => {
            try {
                const web3 = new Web3(window.ethereum);
                const contract = new web3.eth.Contract(stashAbi, stashAddress);

                const owner = await contract.methods.tokenCreator().call();
                const lockData = await contract.methods.getLocks(owner).call();

                const now = Math.floor(Date.now() / 1000);

                const formattedLocks = lockData.map(lock => ({
                    label: lock[2],
                    amount: parseFloat(web3.utils.fromWei(lock[0], "ether")),
                    daysRemaining: ((convertBigIntToFloat(lock[1], 0) - now) / 86400).toFixed(2)
                }));

                const sortedLocks = formattedLocks.sort((a, b) => {
                    if (a.daysRemaining < 0 && b.daysRemaining < 0) return 0; // Both ready to unlock, keep order
                    if (a.daysRemaining < 0) return -1; // `a` ready to unlock, move to top
                    if (b.daysRemaining < 0) return 1; // `b` ready to unlock, move to top
                    return a.daysRemaining - b.daysRemaining; // Sort remaining in descending order
                });

                setOwner(owner);
                setLocks(sortedLocks);

            } catch (error) {
                console.error("Error fetching stash data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStashInfo();
    }, [stashAddress]);

    return (
        <div className="stash-widget">
            <Navbar />
            <h1 id="stash-title">ShidoKid's Stash</h1>
            {loading ? (
                <p>Loading stash information...</p>
            ) : locks.length === 0 ? (
                <p>No KIDDO locked yet.</p>
            ) : (
                <div className="stash-table">
                    <p><strong>Owner:</strong> <code>{owner}</code></p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Label</th>
                                <th>Amount</th>
                                <th>Days Remaining</th>
                            </tr>
                        </thead>
                        <tbody>
                            {locks.map((lock, index) => (
                                <tr key={index}>
                                    <td>{lock.label}</td>
                                    <td>{lock.amount.toLocaleString()} KIDDO</td>
                                    <td>{
                                        lock.daysRemaining < 0
                                            ? "Ready to Unlock"
                                            : lock.daysRemaining
                                    }</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default StashWidget;