import React, { useState } from "react";

import { Link } from 'react-router-dom';
import "../css/tools.css"
import "../css/App.css"
import Navbar from "../widgets/NavBar"

function Tools() {
    const [account, setAccount] = useState("");
    const handleLogout = () => {
        setAccount("");
    };
    console.log(account);

    return (
        <div className="tools">
            <Navbar handleLogout={handleLogout} />
            <h1 className="title" id="tools-title" >ShidoKid Tools</h1>
            <div className="tools-grid">
                <div className="tool-container">
                    <h2>Batch Shido Transfers</h2>
                    <p className="tool-description">Have you ever run a giveaway or had to send SHIDO to multiple addresses? Did you sent each transaction one by one? with this tool you can do it with a single transaction.</p>
                    <Link className="tool-btn" to="/tools/batch-shido">Batch Shido Transfers</Link>
                </div>
                <div className="tool-container">
                    <h2>Batch SHI20 Transfers</h2>
                    <p className="tool-description">Send your favourite community tokens to multiple addresses and amounts, with ease.</p>
                    <Link className="tool-btn" to="/tools/batch-shi20">Batch shi20 Transfers</Link>
                </div>


            </div>
        </div >
    );
}

export default Tools;
